<template>
  <div>
    <h4>Agency Options</h4>

    <div class="card mt-4 ">
      <div class="card-header">
        <small>Use this menu to change the data related to the agency</small>
      </div>
      <div class="card-body">
        <FormInput
          v-model="agency_data.agency_fee"
          type-number
          label="Agency Fee"
        />
        <button
          class="btn btn-success mt-2 btn-sm"
          @click="saveForm"
        >
          <i class="fas fa-save mr-2" />Save
        </button>
      </div>
    </div>

    <div class="card mt-4 ">
      <div class="card-header">
        <h4>Budgets</h4>
      </div>
      <div class="card-body">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="budgets"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @onPressDelete="onPressDelete"
          @onShowDetails="onShowDetails"
        />
      </div>
    </div>

    <budgetDetails
      v-if="selBudget"
      :budget="selBudget"
      @close="selBudget=null"
    />
  </div>
</template>

<script>
import {
  FormInput,
} from '@/components/inputs';
import { mapActions, mapGetters } from 'vuex';
import budgetDetails from './budgetDetails.vue';

export default {
  components: {
    FormInput,
    budgetDetails,
  },
  data: () => ({
    selBudget: null,
    form: {
      agency_fee: null,
    },
    vTable: {
      headers: [
        {
          title: 'created_at',
          mask: 'date',
          sortable: true,
          dateFormat: 'DD-MM-YYYY HH:mm',
        },
        {
          title: 'full_name',
          mask: 'full name',
          sortable: true,
        },
        {
          title: 'email',
          sortable: true,
        },
        {
          title: 'phone',
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: 'btn-info btn-sm', // use any class you want
          callback: 'onShowDetails', // action you have to subscribe in the vue-table element
          tooltip: 'Details', // tooltip on hover
          icon: 'fas fa-search', // You can use any icon package, just install it before
        },
        {
          buttonClass: 'btn-danger btn-sm', // use any class you want
          callback: 'onPressDelete', // action you have to subscribe in the vue-table element
          tooltip: 'Delete', // tooltip on hover
          icon: 'fas fa-times', // You can use any icon package, just install it before
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'agency_data',
      'budgets',
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'getAgencyData',
      'updateAgencyData',
      'getBudgets',
      'deleteBudget',
    ]),
    init() {
      this.getAgencyData()
        .catch(this.showError);
      this.$refs.vtable.init();
    },
    saveForm() {
      let valid = true;
      if (!this.agency_data.agency_fee) {
        this.$noty.error('Agency fee is required');
        valid = false;
      }

      if (valid) {
        this.updateAgencyData(this.agency_data)
          .then(() => {
            this.$noty.success('Agency Data saved!');
          })
          .catch(this.showError);
      }
    },
    getData(evt = {}) {
      this.getBudgets(evt).catch(this.showError);
    },
    onPressDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteBudget(item)
          .then(() => {
            this.$noty.success('Budget Deleted Succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
    onShowDetails(item) {
      this.selBudget = item;
    },
    resetTable() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style>

</style>
