<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      <b>Budget {{ ('00000000'+budget.id).slice(-6) }}</b>
    </template>
    <template slot="body">
      <table class="table">
        <tr>
          <th>Date</th>
          <td align="right">
            {{ dateFormat(budget.created_at) }}
          </td>
        </tr>
        <tr>
          <th>Customer</th>
          <td align="right">
            {{ budget.full_name }}
          </td>
        </tr>
        <tr>
          <th>Nationality</th>
          <td align="right">
            {{ budget.details.nationality.nationality }}
          </td>
        </tr>
        <tr>
          <th>Visa Required</th>
          <td align="right">
            {{ budget.details.nationality.need_visa ? 'YES' : 'NO' }}
          </td>
        </tr>
        <tr>
          <th>Email</th>
          <td align="right">
            {{ budget.email }}
          </td>
        </tr>
        <tr>
          <th>Phone</th>
          <td align="right">
            {{ budget.phone || '-' }}
          </td>
        </tr>
      </table>
      <h4>Details</h4>
      <table class="table">
        <tr>
          <th>
            Course Type
          </th>
          <td align="right">
            {{ course.type.name }}
          </td>
        </tr>
        <tr>
          <th>
            Course Name
          </th>
          <td align="right">
            {{ course.name }}
          </td>
        </tr>
        <tr>
          <th>
            Course Week hours
          </th>
          <td align="right">
            {{ course.week_hours }}
          </td>
        </tr>
        <tr>
          <th>
            Academy
          </th>
          <td align="right">
            {{ academy.name }} - {{ academy.city.name }}
          </td>
        </tr>
        <tr>
          <th>
            Course Weeks
          </th>
          <td align="right">
            {{ budget.details.course_weeks }}
          </td>
        </tr>
        <template v-if="accommodation">
          <tr>
            <th>
              Accommodation
            </th>
            <td align="right">
              {{ budget.details.accommodation.type.name }} -
              <span v-if="budget.details.accommodation.rooms[0].max_people > 1">
                Shared room ({{ budget.details.accommodation.rooms[0].max_people }} people)
              </span>
              <span v-else>
                Single room
              </span>
            </td>
          </tr>
          <tr>
            <th>
              Bathroom Type
            </th>
            <td align="right">
              {{ budget.details.accommodation.rooms[0].bathroom_type.name }}
            </td>
          </tr>
          <tr>
            <th>
              Course Duration <small>(weeks)</small>
            </th>
            <td align="right">
              {{ budget.details.course_weeks }}
            </td>
          </tr>
          <tr>
            <th>
              Accommodation Duration <small>(weeks)</small>
            </th>
            <td align="right">
              {{ budget.details.accommodation_weeks }}
            </td>
          </tr>
          <tr>
            <th>
              Accommodation Starting Date
            </th>
            <td align="right">
              {{ dateFormat(budget.details.course_start_date) }}
            </td>
          </tr>
        </template>
        <tr>
          <th>
            Course Starting Date
          </th>
          <td align="right">
            {{ dateFormat(budget.details.course_start_date) }}
          </td>
        </tr>
        <tr v-if="budget.details.with_transfer">
          <th>
            Transfer from Airport
          </th>
          <td align="right">
            Included
          </td>
        </tr>
      </table>

      <ul class="list-unstyled priceResume">
        <li>
          Course: €{{ coursePrice.toFixed(2) }}
        </li>
        <li v-if="accommodation">
          Accommodation: €{{ (room.price_per_week * budget.details.accommodation_weeks).toFixed(2) }}
        </li>
        <li>
          Enrolment Fee: €{{ academy.enrolment_fee.toFixed(2) }}
        </li>
        <li>
          Book Fee: €{{ academy.book_fee.toFixed(2) }}
        </li>
        <li>
          Agency Fee: €{{ budget.details.agency_fee.toFixed(2) }}
        </li>
        <li v-if="budget.details.transfer_fee">
          Transfer: €{{ budget.details.transfer_fee.toFixed(2) }}
        </li>
        <li class="total">
          Total: €{{ total }}
        </li>
      </ul>
    </template>
  </vue-modal>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    budget: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
  }),
  computed: {
    accommodation() {
      return this.budget.details.accommodation || null;
    },
    room() {
      return this.accommodation ? this.accommodation.rooms[0] : null;
    },
    academy() {
      return this.budget.details.academy || null;
    },
    course() {
      return this.academy ? this.academy.courses[0] : null;
    },
    coursePrice() {
      if (this.course.is_work_study) {
        return this.course.prices[0].week_price;
      }
      return this.course.prices[0].week_price * this.budget.details.course_weeks;
    },
    total() {
      let total = 0;

      if (this.academy) {
        total += this.coursePrice;
        total += this.budget.details.agency_fee;
        total += this.academy.enrolment_fee;
        total += this.academy.book_fee;
      }
      if (this.accommodation) {
        total += this.room.price_per_week * this.budget.details.accommodation_weeks;
      }
      if (this.budget.details.transfer_fee) {
        total += this.budget.details.transfer_fee;
      }

      return total.toFixed(2);
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    dateFormat(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },
};
</script>
<style lang="scss" scoped>
.priceResume {
  text-align: right;
  color: #2276c9;
  font-weight: 600;
  padding: 0;
  margin:0 !important;
  font-size: .9em;

  small {
    font-weight: 600;
  }

  .total {
    font-size: 1.5em;
    padding-top: .5em;
  }
}
</style>
